
import { computed, defineComponent, onBeforeMount, reactive } from "vue";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import { useStore } from "vuex";
import { LectureForm, QuestionForm } from "@/types/lectures";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import InputBasic from "@/components/console/inputs/InputBasic.vue";
import LectureEditor from "../LectureEdit/LectureEditor/LectureEditor.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import { useRouter } from "vue-router";
import FileStackService from "@/services/FileStackService.js";
import InputTextBox from "@/components/console/inputs/InputTextBox.vue";

export default defineComponent({
  name: "Lectures",
  components: {
    InputTextBox,
    ButtonBasic,
    LectureEditor,
    InputBasic,
    CardBasic,
    PageHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      existLecture: computed(() => {
        return store.getters["auth/user"].lecture;
      }),
      lectureForm: {
        title: "",
        description: "",
        featuredImage: "",
        featuredVideo: "",
        introHost: "",
        featuredVideoThumbnail: "",
        tags: [],
        questions: [],
      } as LectureForm,
      lectures: computed(() => {
        return store.getters["lectures/getLectures"];
      }),
      lecture: computed(() => {
        return store.getters["lectures/getLecture"];
      }),
      editedLecture: null,
      lectureSchedules: computed(() => {
        return store.getters["lectures/lectureSchedules"];
      }),
      lectureCategories: computed(() => {
        return store.getters["lectures/lectureCategories"];
      }),

      questionForm: [] as QuestionForm[],
      scheduleStartAt: null,
      scheduleFinishAt: null,
      featuredVideoFile: null,
    });

    onBeforeMount(() => {
      getLectures();
      if (!state.existLecture) {
        getLectureCategories();
      }
    });

    const getLectures = (): void => {
      if (state.user.lecture) {
        store.dispatch("lectures/getLecture", {
          resourceId: state.user.lecture.resourceId,
        });
      }
    };
    const getLectureCategories = (): void => {
      store.dispatch("lectures/getLectureCategories");
    };

    const postLectures = (): void => {
      let payload: LectureForm = {
        title: state.lectureForm.title,
        description: state.lectureForm.description,
        featuredImage: state.lectureForm.featuredImage,
        featuredVideo: state.lectureForm.featuredVideo,
        featuredVideoThumbnail: state.lectureForm.featuredVideoThumbnail,
        introHost: state.lectureForm.introHost,
        tags: state.lectureForm.tags,
        questions: state.questionForm,
        categories: [] as any,
      };

      store.dispatch("lectures/postLectures", payload).then(() => {
        console.log("post success");
      });
    };

    const actions = {
      goToLectureDetail: (lecture) => {
        router.push({
          name: "console.lectures.detail",
          params: {
            lectureResourceId: lecture.resourceId,
          },
        });
      },
      createLecture: () => {
        postLectures();
      },
      updateLectureTitle: (value) => {
        state.lectureForm.title = value;
      },
      updateLectureDescription: (value) => {
        state.lectureForm.description = value;
      },
      openImageFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = true;

        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          state.lectureForm.featuredImage = fileMetaData.url;
        };

        fileStack.open(fileStack.options);
      },
      openVideoFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.accept = ["video/ogg", "video/mpeg", "video/webm"];

        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          state.lectureForm.featuredVideo = fileMetaData.url;
          state.featuredVideoFile = fileMetaData;
        };

        fileStack.open(fileStack.options);
      },
      openVideoThumbnailFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = true;

        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          state.lectureForm.featuredVideoThumbnail = fileMetaData.url;
        };

        fileStack.open(fileStack.options);
      },
      goToLectureReserve: (lecture) => {
        router.push({
          name: "console.lectures.reservations",
          params: { lectureResourceId: lecture.resourceId },
        });
      },
    };
    return { state, actions };
  },
});
